import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Contact Us" description="Contact Us" mdxType="SEO" />
    <article className="prose prose-xl mx-auto">
      <h1 {...{
        "id": "contact"
      }}>{`Contact`}</h1>
      <p>{`Questions about products, membership, wholesale?`}</p>
      <p>{`Email bryan at archibaldjames.com. We're happy to help!`}</p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      